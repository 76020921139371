<!-- Template -->
<template>
  <svg 
    class="i-chevron-left"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 14.964 26.783"
  >
    <path 
      d="M36.87,2.5A1.6,1.6,0,0,1,38,2.951,1.566,1.566,0,0,1,38,5.18L27.308,15.9,38,26.589a1.576,1.576,0,1,1-2.228,2.228L23.951,17.027A1.553,1.553,0,0,1,23.5,15.9a1.677,1.677,0,0,1,.451-1.128L35.77,2.951A1.536,1.536,0,0,1,36.87,2.5Z"
      transform="translate(-23.5 -2.5)"
      :fill="fill"
    />
  </svg>
</template>

<!-- Scripts -->
<script>
// Componente
export default {
  // Nome do componente
  name: "IconChevronLeft",
  // Propriedades herdadas
  props: {
    width: { type: Number, default: 14.964 },
    height: { type: Number, default: 26.783 },
    fill: { type: String, default: '#ffffff' },
  },
}
</script>